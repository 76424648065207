import React from 'react';

import DescriptionPanel from '../components/DescriptionPanel';
import EducationPanel from '../components/EducationPanel';
import EircodePanel from '../components/EircodePanel';
import GroceriesPanel from '../components/GroceriesPanel';
import InternetPanel from '../components/InternetPanel';
import LegalPanel from '../components/LegalPanel';
import MapPanel from '../components/MapPanel';
import Master from '../components/Master';
import PhotosPanel from '../components/PhotosPanel';

// import '../style.css';

const Page = ({ pageContext }) => {
    return (
        <Master>
            {/* https://daily-dev-tips.com/posts/center-elements-with-tailwind-css/ */}
            <div className="flex justify-center items-center h-screen">
                <div>
                    <div className="px-5">
                        <h1>{pageContext.Title}</h1>
                        <p>{pageContext.Description}</p>
                    </div>
                    <PhotosPanel />
                    <div>
                        <MapPanel />
                        <EducationPanel />
                        <GroceriesPanel />
                        <LegalPanel />
                        <InternetPanel />
                        <EircodePanel />
                        <DescriptionPanel />
                    </div>
                </div>
            </div>
        </Master>
    );
};

export default Page;
