import React from 'react';

import DistancePanel from './DistancePanel';

const EducationPanel = () => (
    <div className="px-2">
        <div>Education Section</div>
        <p>Closest primary, secondary or special schools</p>

        <DistancePanel
            title={'Primary School:'}
            name={"ST MICHAEL'S INFANT SCHOOL"}
            distance={'1.2 km.'}
            link={
                'https://www.google.com/maps/dir/52.660352,+-8.634485/52.664575,-8.63533'
            }
        ></DistancePanel>

        <DistancePanel
            title={'Secondary School:'}
            name={"ST MICHAEL'S INFANT SCHOOL"}
            distance={'1.2 km.'}
            link={
                'https://www.google.com/maps/dir/52.660352,+-8.634485/52.664575,-8.63533'
            }
        ></DistancePanel>
    </div>
);

export default EducationPanel;
