import React from 'react';

const DistancePanel = ({ logo, title, name, distance, link }) => {
    return (
        <div className="my-2 p-1 bg-slate-300 rounded-md">
            <div className="inline-flex items-center">
                <div className="m-1">{logo}</div>
                <div>
                    <div>{title}</div>
                    <div className="font-bold">{name}</div>
                    <div className="inline-flex divide-x divide-gray-400 text-md text-sm">
                        <div className="pr-2 inline-flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    // stroke-linecap="round"
                                    // stroke-linejoin="round"
                                    // stroke-width="2"
                                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                                />
                            </svg>
                            <span className="ml-1">
                                {distance}
                                <a
                                    className="ml-1"
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Open route
                                </a>
                            </span>
                        </div>

                        {/* <div className="pr-2">
                                <StaticImage
                                    src="../images/icon/car-icon.png"
                                    alt="by private transport"
                                />
                                3 min. by car
                            </div>
                            <div className="px-2">
                                <StaticImage
                                    src="../images/icon/bus-icon.png"
                                    alt="by public transport"
                                />
                                5 min. by bus
                            </div>
                            <div className="px-2">
                                <StaticImage
                                    src="../images/icon/walk-icon.png"
                                    alt="walking"
                                />
                                10 min walking
                            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DistancePanel;
