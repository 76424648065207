import React from 'react';

import EircodeIcon from '../images/icon/eircode-icon2.png';
import CostPanel from './CostPanel';

const EircodePanel = () => (
    <div className="px-2">
        <div>EirCode</div>
        {/* <p>Broadband, Fiber, Mobile Data</p> */}

        <CostPanel
            logo={
                <img
                    className="w-12"
                    src={EircodeIcon}
                    alt="eircode icon"
                ></img>
            }
            name={'V94 TQ235'}
        ></CostPanel>
    </div>
);

export default EircodePanel;
