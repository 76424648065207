import React, { useState } from 'react';

const PhotosPanel = () => {
    const images = ['cama.jpg', 'sala.jpg'];
    const altImages = ['cama.jpg', 'sala.jpg'];
    const [currentIndexImage, setCurrentIndexImage] = useState(0);

    const nextImage = () => {
        var next = currentIndexImage + 1;

        if (images.length > next) {
            setCurrentIndexImage(next);
        } else {
            setCurrentIndexImage(0);
        }
    };

    const previousImage = () => {
        var previous = currentIndexImage - 1;

        if (previous < 0) {
            setCurrentIndexImage(images.length - 1);
        } else {
            setCurrentIndexImage(previous);
        }
    };

    return (
        <div className="divide-y divide-gray-300 text-sm">
            <div className="py-2">
                <div className="relative max-w-max">
                    <img
                        src={`/images/property/${images[currentIndexImage]}`}
                        alt={altImages[currentIndexImage]}
                    />
                    <div className="absolute left-0 top-0 bg-slate-700/70 mt-1 ml-1 px-2 py-1 rounded-sm text-white text-xs ">
                        <span>
                            {currentIndexImage + 1}/{images.length}
                        </span>
                    </div>

                    {/* https://stackoverflow.com/questions/60362442/cant-center-absolute-position-tailwind-css */}

                    <a href="/#" onClick={() => previousImage()}>
                        <div className="absolute top-1/2 left-1 transform -translate-y-1/2 bg-slate-700/70 py-2 rounded-sm text-white text-xs">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                        </div>
                    </a>

                    <a href="/#" onClick={() => nextImage()}>
                        <div className="absolute top-1/2 right-1 transform -translate-y-1/2 bg-slate-700/70 py-2 rounded-sm text-white text-xs">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </div>
                    </a>
                </div>

                <div className="px-2">
                    <div>
                        <div className=" text-slate-800 text-2xl">€ 123.44</div>
                    </div>
                    <div className="inline-flex divide-x divide-gray-400 text-md">
                        <div className="pr-2">2 bds</div>
                        <div className="px-2">3 ba</div>
                        <div className="px-2">123 m.</div>
                        <div className="px-2">Apartment</div>
                    </div>
                    <div className="pt-2">
                        Ord Cratloe Road, Clonconane, Co. Limerick{' '}
                        <span className="whitespace-nowrap">V94 RW11</span>
                    </div>
                    <div className="pt-2 border-b-2 border-gray-400 mb-2 pb-2">
                        Apartment
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhotosPanel;
