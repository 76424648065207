import React from 'react';

import RevenueIcon from '../images/icon/revenue-icon3.png';
import CostPanel from './CostPanel';

const LegalPanel = () => (
    <div className="px-2">
        <div>Legal Costs</div>
        <p>How much cost the local property tax</p>

        <CostPanel
            logo={
                <img
                    className="w-12"
                    src={RevenueIcon}
                    alt="revenue icon"
                ></img>
            }
            title={'Local Property Tax'}
            name={'Year: 2022'}
            text={'€ 1235.00 / year'}
        ></CostPanel>

        <CostPanel
            logo={
                <img
                    className="w-12"
                    src={RevenueIcon}
                    alt="revenue icon"
                ></img>
            }
            title={'Stamp Duty'}
            name={'Year: 2022'}
            text={'€ 1234 /year'}
        ></CostPanel>
    </div>
);

export default LegalPanel;
