import React from 'react';

const DescriptionPanel = () => (
    <div className="px-2">
        <div>Description</div>

        <p>
            Large single room to rent in a nice quite part of naas town, House
            is fully furnsihed ,Aldi 5 mins walk, town centre 15 mins walk.
            Security gate development /parking / Sharing with 2 x females .
            Access to the main m7 motorway.
        </p>
    </div>
);

export default DescriptionPanel;
