import React from 'react';

import VodafoneIcon from '../images/icon/vodafone-icon.png';
import CostPanel from './CostPanel';

const InternetPanel = () => (
    <div className="px-2">
        <div>Internet Availability</div>
        <p>Broadband, Fiber, Mobile Data</p>

        <CostPanel
            logo={
                <img className="w-12" src={VodafoneIcon} alt="vodafone"></img>
            }
            title={'Vodafone'}
            name={'Broandband'}
            text={'€ 35 /month'}
        ></CostPanel>
    </div>
);

export default InternetPanel;
