import React from 'react';

import AldiIcon from '../images/icon/aldi-icon.png';
// import CentraIcon from '../images/icon/centra-icon.png';
// import CostCutterIcon from '../images/icon/costcutter-icon.svg';
// import DunnesStoresIcon from '../images/icon/dunnes-stores-icon.png';
// import EuroSparIcon from '../images/icon/eurospar-icon.svg';
// import GalaIcon from '../images/icon/gala-icon.jpg';
// import LidlIcon from '../images/icon/lidl-icon.png';
// import MaceIcon from '../images/icon/mace-icon.png';
// import SparIcon from '../images/icon/spar-icon.svg';
// import SuperValuIcon from '../images/icon/supervalu-icon.png';
import TescoIcon from '../images/icon/tesco-icon.png';
import DistancePanel from './DistancePanel';

// https://en.wikipedia.org/wiki/List_of_supermarket_chains_in_Ireland

const GroceriesPanel = () => (
    <div className="px-2">
        <div>Groceries Section</div>
        <p>Closest groceries, supermarkets and convenience stores</p>

        <DistancePanel
            logo={<img className="w-12" src={TescoIcon} alt="tesco"></img>}
            name={'Tesco Limerick'}
            distance={'1.2 km.'}
            link={
                'https://www.google.com/maps/dir/52.660352,+-8.634485/52.664575,-8.63533'
            }
        ></DistancePanel>
        {/* <img className="w-16" src={CostCutterIcon}></img>
   <img className="w-16" src={DunnesStoresIcon}></img>
   <img className="w-16" src={EuroSparIcon}></img>
   <img className="w-16" src={AldiIcon}></img>
   <img className="w-16" src={CentraIcon}></img>
   <img className="w-16" src={GalaIcon}></img>
   <img className="w-16" src={LidlIcon}></img>
   <img className="w-16" src={MaceIcon}></img>
   <img className="w-16" src={SparIcon}></img>
   <img className="w-16" src={SuperValuIcon}></img> */}

        <DistancePanel
            logo={<img className="w-12" src={AldiIcon} alt="aldi"></img>}
            name={'Aldi Proximate'}
            distance={'1.2 km.'}
            link={
                'https://www.google.com/maps/dir/52.660352,+-8.634485/52.664575,-8.63533'
            }
        ></DistancePanel>
    </div>
);

export default GroceriesPanel;
