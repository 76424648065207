import React from 'react';

const CostPanel = ({ logo, title, name, text, link }) => {
    return (
        <div className="my-2 p-1 bg-slate-300 rounded-md">
            <div className="inline-flex items-center">
                <div className="m-1">{logo}</div>
                <div>
                    <div>{title}</div>
                    <div className="font-bold">{name}</div>
                    <div className="inline-flex divide-x divide-gray-400 text-md text-sm">
                        <div className="pr-2 inline-flex items-center">
                            <span className="ml-1">{text}</span>
                        </div>

                        {/* <div className="pr-2">
                                <StaticImage
                                    src="../images/icon/car-icon.png"
                                    alt="by private transport"
                                />
                                3 min. by car
                            </div>
                            <div className="px-2">
                                <StaticImage
                                    src="../images/icon/bus-icon.png"
                                    alt="by public transport"
                                />
                                5 min. by bus
                            </div>
                            <div className="px-2">
                                <StaticImage
                                    src="../images/icon/walk-icon.png"
                                    alt="walking"
                                />
                                10 min walking
                            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CostPanel;
