import React, { useState } from 'react';

import Map from '../components/Maps/Map';

const MapPanel = () => {
    const [map, setMap] = useState(<></>);

    const renderMaps = (map) => {
        switch (map.toString().toLowerCase()) {
            case 'satellite':
                return setMap(
                    <Map
                        lat={53.33517151853}
                        lng={-6.2828424414576}
                        zoom={18}
                        showMarker={true}
                        mapStyle="satellite"
                    />,
                );

            case 'street':
                return setMap(
                    <Map
                        lat={53.33517151853}
                        lng={-6.2828424414576}
                        zoom={18}
                        showMarker={true}
                        mapStyle="street"
                    />,
                );
            default:
                return setMap(
                    <Map
                        lat={53.33517151853}
                        lng={-6.2828424414576}
                        zoom={18}
                        showMarker={true}
                    />,
                );
        }
    };

    return (
        <div>
            <div className="grid grid-flow-col place-items-stretch gap-4 mx-4">
                <button
                    className="bg-blue-500 rounded-md"
                    onClick={() => renderMaps('map')}
                >
                    Map
                </button>
                <button
                    className="bg-blue-500 rounded-md"
                    onClick={() => renderMaps('satellite')}
                >
                    Satellite
                </button>
                <button
                    className="bg-blue-500 rounded-md"
                    onClick={() => renderMaps('street')}
                >
                    Street
                </button>
            </div>
            <div className="py-2">{map}</div>
        </div>
    );
};

export default MapPanel;
